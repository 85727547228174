import React, { useState } from "react";
import { ReactComponent as Logo2 } from "../../assets/auth/logo.svg";
import { ReactComponent as CloseIcon2 } from "../../assets/icons/hotspot-hubs/close.svg";
import { NavBarData, NavBarDataMore } from "./NavBarData";
import { Link, NavLink, useLocation } from "react-router-dom";
import RouterLogin from "../../assets/icons/router-login.svg";
import { ReactComponent as ViewAll } from "../../assets/icons/view-all.svg";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import { useAppDispatch } from "../../app/hooks";
import { toggleAppModal } from "../../features/store/authReducerSlice";
import NotifyMobile from "../partials/NotifyMobile";

const NavBar: React.FC<NavbarProps> = ({
  isNavbarOpen,
  handleCloseNav,
  navRef,
}) => {
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);
  const [showNavbarMore, setShowNavbarMore] = useState(false);

  const location = useLocation();

  const handleSelect = (id: number) => {
    setSelected(id);
    if (handleCloseNav) {
      handleCloseNav();
    }
  };

  const handleNavbarToggle = () => {
    setShowNavbarMore(!showNavbarMore);
    setShowNavbar(!showNavbar);
  };

  return (
    <section
      className={`fixed inset-0 z-[19] lg:z-auto h-screen overflow-hidden bg-[#00000033] dark:md:bg-[#00000080] lg:relative lg:block lg:w-[16.5rem] ${isNavbarOpen ? "block" : "hidden"
        }`}
      ref={navRef}
    >
      <div
        className={`grid grid-rows-[88px_1fr] lg:block overflow-y-auto h-screen pb-8 px-6 w-[16.5rem] bg-white dark:bg-clrDarkBg z-40 inset-y-0 left-0`}
      >
        <div className="sticky top-0 z-10 flex items-center justify-between bg-white dark:bg-clrDarkBg lg:justify-center">
          {showNavbarMore && (
            <button
              className="pt-3 md:mr-5 btn-reset"
              onClick={handleNavbarToggle}
            >
              <ArrowBack className="w-5 h-5 dark-arrow" />
            </button>
          )}

          <Logo2 className="w-[106.3px] h-[90px] dark-logo" />
          <button onClick={handleCloseNav} className="pt-3 lg:hidden">
            <CloseIcon2 className="w-[13px] h-[13px] dark-close" />
          </button>
        </div>
        {showNavbar && (
          <section className="overflow-y-auto">
            <div className="flex flex-col gap-2 pt-2">
              {NavBarData.map((item, index) => {
                return (
                  <NavLink
                    className={`nav-group flex items-start gap-3.5 px-2 py-3 active:bg-clrBtnNav dark:hover:bg-clrDarkBg2 hover:rounded-xx hover:bg-clrBtnNav dark:active:bg-clrDarkBg2 rounded-xx ${location.pathname.split("/")[2] === item.path
                        ? " bg-clrBtnNav dark:bg-clrDarkBg2"
                        : ""
                      }`}
                    key={item.id}
                    onClick={() => handleSelect(item.id)}
                    to={item.to}
                  >
                    <div
                      className={
                        location.pathname.split("/")[2] === item.path
                          ? "icon-nav icon-active "
                          : "icon-nav"
                      }
                    >
                      {item.icon}
                    </div>

                    <div>
                      <p
                        className={`nav-title text-xs pb-1 font-medium text-clrGray dark:text-clrPlaceholder self-center ${location.pathname.split("/")[2] === item.path
                            ? "font-semibold text-clrTextBlue dark:text-white"
                            : ""
                          }`}
                      >
                        {item.title}
                      </p>
                      {location.pathname.split("/")[2] === item.path ? (
                        <p className="nav-desc text-[0.5rem] font-normal text-clrGray tracking-[0.4px]">
                          {item.desc}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </NavLink>
                );
              })}
              <button
                className="nav-view-more flex mb-3 gap-3.5 px-2 py-3 text-clrGray dark:text-clrPlaceholder dark:hover:text-clrPlaceholder hover:text-clrTextBlue hover:font-semibold hover:rounded-xx hover:bg-clrBtnNav dark:hover:bg-clrDarkBg2"
                onClick={handleNavbarToggle}
              >
                <div className="icon-nav">
                  <ViewAll />
                </div>
                <p className="text-xs nav-title ">View More</p>
              </button>
            </div>
            <NotifyMobile />
          </section>
        )}
        {showNavbarMore && (
          <section className="relative">
            <div className="relative flex flex-col gap-3 mb-8 gap-x-4">
              {NavBarDataMore.map((item, index) => {
                return (
                  <NavLink
                    className={`nav-group flex items-start gap-3.5 px-2 py-3 active:bg-clrBtnNav dark:active:bg-clrDarkBg2 hover:rounded-xx hover:bg-clrBtnNav dark:hover:bg-clrDarkBg2 rounded-xx ${location.pathname.split("/")[2] === item.path
                        ? " bg-clrBtnNav dark:bg-clrDarkBg2"
                        : ""
                      }`}
                    key={item.id}
                    onClick={() => handleSelect(item.id)}
                    to={item.to}
                  >
                    <div
                      className={
                        location.pathname.split("/")[2] === item.path
                          ? "icon-active icon-nav"
                          : "icon-nav"
                      }
                    >
                      {item.icon}
                    </div>

                    <div>
                      <p
                        className={`nav-title text-xs pb-1 font-medium text-clrGray dark:text-clrPlaceholder self-center ${location.pathname.split("/")[2] === item.path
                            ? "font-semibold text-clrTextBlue dark:text-white"
                            : ""
                          }`}
                      >
                        {item.title}
                      </p>
                      {location.pathname.split("/")[2] === item.path && (
                        <p className="text-[0.5rem] font-normal text-clrGray tracking-[0.4px]">
                          {item.desc}
                        </p>
                      )}
                    </div>
                  </NavLink>
                );
              })}
            </div>
          </section>
        )}
        <button
          onClick={() =>
            dispatch(toggleAppModal({ id: "routerCode", detail: "routerCode" }))
          }
          className="mt-6 relative z-10 flex items-center justify-center gap-2 w-full p-3 rounded-xii bg-[#3D529A] dark:bg-clrYellow2 overflow-hidden"
        >
          <img src={RouterLogin} alt="Login" width={30} height={30} />
          <p className="text-sm font-medium text-white">My Custom Code</p>
          <div className="absolute inset-0 z-[-1] w-full h-full bg-[#304487] dark:bg-clrDarkYellow rounded-lg before transform -translate-x-1/2 -rotate-12 "></div>
        </button>
      </div>
    </section>
  );
};

export default NavBar;
