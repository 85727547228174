import { useState } from 'react';
import { Button, EmptyCard, TableLoader } from '../reusables';
import { ScaleLoader } from 'react-spinners';

import { ReactComponent as DeviceMain } from '../../assets/icons/hotspot-hubs/device-with-bg.svg';
import { ReactComponent as OnlineIcon } from '../../assets/icons/hotspot-hubs/hubs-with-ads.svg';
import { adOverviewColumn } from '../hotspotHubs/ReactTableDataList';
import TableMain from '../reusables/ReactDataTable';
import { RequestWithdrawal } from './AdvertModals';
import { useDashboardNetworksQuery, useGetAmountEarnedByHubsQuery, useGetDevicesQuery } from '../../features/api/advertSlice';

const Overview = () => {
  const [deviceToSearch, setDeviceSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [showTransDetails, setShowTransDetails] = useState<boolean>(false);

  const { data: dashboardNetworks, isLoading } = useDashboardNetworksQuery();

  const { data: allAdDevices, isLoading: gettingDevices } = useGetDevicesQuery({
    deviceName: deviceToSearch,
    deviceStatus: '',
    page: page,
    pageSize: 30,
  });

  const { data } = useGetAmountEarnedByHubsQuery();
  const hubs: any = allAdDevices?.data || [];

  const getHubsWithAds = () =>{
    let count = 0;
    for(let i=0; i< hubs.length; i++){
      if(hubs[i].is_enabled){
        count++
      }
    }
     return count;
  }

  const getAmountEarnedByHubs = data?.data;

  return (
    <section className="w-[96%] mx-auto mt-3.5 md:mt-0 mb-6  overflow-y-auto">
      <div className="grid grid-cols-1 gap-2 px-3 py-8 mb-2 bg-white rounded-lg dark:bg-clrDarkBg md:grid-cols-[200px_1fr_1fr] xl:grid-cols-[200px_350px_350px] md:gap-4">

        <div className="flex items-center gap-2 md:gap-4 mr-auto w-full md:min-w-[200px] [&>*]:tracking-wider bg-white dark:bg-clrDarkBg border-[#C4C4C44D] rounded-[10px] p-3 border border-solid">
          <Button
            type="button"
            text="Request Withdrawal"
            handleClick={()=>{setShowTransDetails(true)}}
            textSize="text-x10"
            py="py-0.5 md:py-1"
            px='px-2 md:px-4'
            fontWeight="font-semibold"
            wBtn='!w-fit'
          />
        </div>

        <div className="flex items-center gap-2 md:gap-4 bg-white dark:bg-clrDarkBg border-[#C4C4C44D] rounded-[10px] p-3 border border-solid">
          <DeviceMain width={35} height={35} />
          <div className="mr-auto">
            <p className="text-xs font-semibold text-clrTextLightGray dark:text-clrPlaceholder">
              Total Hubs
            </p>
            <p className="mt-3 text-xl font-semibold md:text-2xl text-clrTextGray dark:text-white">
              {isLoading ? (
                <ScaleLoader
                  loading={isLoading}
                  height={12}
                  width={2}
                  color={'#3d5170'}
                />
              ) : (
                dashboardNetworks?.data?.total_hubs
              )}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2 md:gap-4 bg-white dark:bg-clrDarkBg border-[#C4C4C44D] rounded-[10px] p-3 border border-solid">
          <OnlineIcon width={35} height={35} />
          <div className="mr-auto">
            <p className="text-xs font-semibold text-clrTextLightGray dark:text-clrPlaceholder">
              Hubs with Ads
            </p>
            <p className="mt-3 text-xl font-semibold md:text-2xl text-clrTextGray dark:text-white">
              {gettingDevices ? (
                <ScaleLoader
                  loading={gettingDevices}
                  height={12}
                  width={2}
                  color={'#3d5170'}
                />
              ) : (
                getHubsWithAds()
              )}
            </p>
          </div>
        </div>

      </div>
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-6">
        <p className="text-sm font-semibold text-black dark:text-clrPlaceholder">
          Ad Views History
        </p>
        <div className="flex flex-wrap items-end gap-3 mb-8 md:flex-nowrap">
          {/* <div className="flex w-full gap-3 md:w-auto">
            <div className="flex-1 md:flex-auto ">
              <label
                htmlFor=""
                className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
              >
                Search
              </label>
              <input
                type="text"
                name="Hub Name"
                onChange={(e) => setDeviceSearch(e.target.value)}
                value={deviceToSearch}
                placeholder="Hub Name"
                className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
              />
            </div>
          </div> */}
        </div>
        <div>
          {isLoading ? (
            <TableLoader />
          ) : (
            <TableMain
              defaultData={getAmountEarnedByHubs || []}
              columns={adOverviewColumn}
              totalPages={getAmountEarnedByHubs?.length || 0}
              pageSize={30}
              currentPage={page || 0}
              setPage={setPage}
            />
          )}
          {!isLoading && (!data || !getAmountEarnedByHubs || getAmountEarnedByHubs.length === 0) && (
            <EmptyCard
              description={"No Advert summary"}
            />
          )}
        </div>
      </div>
      {showTransDetails && (
        <RequestWithdrawal
          handleModal={() => setShowTransDetails(false)}
        />
      )}
    </section>
  );
};

export default Overview;
