import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { ReactComponent as EmptyFeature } from "../../assets/subscription/empty-feature.svg";
import { Button, TableLoader } from "../reusables";
import Features from "./Features";
import { useGetActivePackageQuery, useGetPackagesQuery, useLazyGetPackageQuery } from "../../features/api/subscriptionSlice";
import { useAppSelector } from "../../app/hooks";
import { numberWithCommasWithoutDecimal } from "../../Helper";

interface PackageDetail {
  [id: string]: any[];
}

const Plan = ({ planType }: { planType: number }) => {
  const { id: userId } = useAppSelector((state) => state.auth);
  const [packageDetail, setPackageDetail] = useState<PackageDetail>();

  const [trigger] = useLazyGetPackageQuery();
  const { data: packages, isLoading } = useGetPackagesQuery();
  const { data: activePackage } = useGetActivePackageQuery(userId);

  // Function to get the details for each plan by id 
  const getPackageDetail = (id: string) => {
    trigger(id)
      .unwrap()
      .then((res) => {
        setPackageDetail((prevPackageDetail) => ({
          ...prevPackageDetail,
          [id]: res.data.featureList
        }));
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (packages) {
      packages.data.map((each) => {
        getPackageDetail(each.id)
      })
    }
  }, [packages])

  //if we re subscribed on that one, render the yellow btn
  //if we re subscribed but not on pro, render the upgrade button on the pro card 
console.log(activePackage, packages, 'i am active')

  return (
    <>
      {isLoading ? <div className="m-auto" ><TableLoader />  </div> :

        <section className="grid items-center grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3">
          {packages?.data.map((each, index) => {
            return (
              <div key={index} className={`relative 
min-h-[631.062px] border border-solid border-primary/20 pt-9 
px-[1.4rem] pb-4 ${each.name === "Free" ? "plan-gradient-grey" : each.name === "Standard" ? "plan-gradient-yellow" : "plan-gradient-black"}  bg-white rounded-3xl 
before:content[' '] before:absolute before:w-full before:h-[24px] 
before:rounded-t-3xl before:top-0 before:left-0 before:right-0`}>
                <div className="flex flex-col items-center justify-center gap-3">
                  <h4 className="text-sm font-medium text-clrGray">{each.name} </h4>
                  <p className="text-xl font-semibold text-black">
                    {planType === 1 ?
                      each.monthlyFee === 0 ? "Free" : numberWithCommasWithoutDecimal(each.monthlyFee || 0) + "NGN/month" :
                      each.annualFee === 0 ? "Free" : numberWithCommasWithoutDecimal(each.annualFee || 0) + "NGN/year"}
                  </p>
                  <p className="text-sm font-medium text-clrGray">{each.name === "Free" ? "Forever" : planType === 1 ? "Billed Monthly" : "Billed Yearly"}</p>
                </div>
                {packageDetail?.[each.id]?.length ?? 0 > 0 ?
                  <div className="mt-2 bg-primary bg-opacity-[0.02] rounded-2xl border border-primary/10 border-solid py-5 px-4">
                    <h5 className="mb-4 text-xs font-semibold text-clrGray"> Everthing included in the {each.name} </h5>
                    <div className="grid gap-4">
                      {packageDetail?.[each.id]?.map((feature) => {
                        return (<Features key={feature.name} feature={feature.name} description={feature.description} more={feature.configurations} />)
                      })}
                    </div>
                  </div> :
                  <div className="flex flex-col justify-center items-center mt-2 bg-primary bg-opacity-[0.02] rounded-2xl 
    border border-primary/10 border-solid p-5 min-h-[419px] py-5 px-4">
                    <EmptyFeature />
                    <p className="text-sm font-medium text-clrGray">No special features</p>
                  </div>
                }
                {
                  each?.name.toLowerCase() !== "free" ?
                    activePackage ?
                      activePackage?.data?.subscriptionPackageId === each.id ?
                        <Button
                          text={`${activePackage?.data?.subscriptionName} Plan is Active`}
                          handleClick={() => { }}
                          type="button"
                          wBtn="w-fit"
                          fontWeight="font-normal cursor-default"
                          px={"px-6"}
                          bgColor={"bg-[#E5B910] opacity-30"}
                        /> 
                        :
                        activePackage?.data?.subscriptionName.toLowerCase() === "standard" &&
                        each.name.toLowerCase()! === "standard" &&
                        <Button
                          text={"Upgrade to Pro"}
                          handleClick={() => { }}
                          type="button"
                          wBtn="w-fit"
                          fontWeight="font-normal"
                          px={"px-6"}
                          bgColor={each.id === activePackage.data.subscriptionPackageId ? "bg-clrRed" : "bg-black"}
                        />
                      :
                      // <Link to={`/home/subscription/${each.id}/${planType}`} className="block w-full m-auto mt-5 md:w-fit">
                        <div className="block w-full m-auto mt-5 md:w-fit">
                          <Button
                            text={each.name === "Standard" ? "Choose Standard" : "Choose Pro"}
                            handleClick={() => { }}
                            type="button"
                            wBtn="w-fit"
                            fontWeight="font-normal"
                            px={"px-6"}
                            bgColor={each.name === "Standard" ? "bg-primary" : "bg-black"}
                          />
                        </div>
                      // </Link>
                    : null
                }

              </div>
            )
          })}
        </section>}
    </>
  )
}

export default Plan
