import { ReactComponent as Devices } from "../../assets/icons/devices-yellow.svg";
import { ReactComponent as DataBought } from "../../assets/icons/data-bought.svg";
import {ReactComponent as ParentalControl} from "../../assets/icons/parental-control.svg";
import {ReactComponent as Token} from "../../assets/icons/Token.svg";
import {ReactComponent as Balance} from "../../assets/dashboard/balance.svg";
import {ReactComponent as Caption} from "../../assets/dashboard/create.svg";
import {ReactComponent as Business} from "../../assets/dashboard/business.svg";
import {ReactComponent as HubSetup} from "../../assets/dashboard/set-up-hub.svg";

export const quickActionData = [
  {
    icon: <Devices height="30px" className="self-start" width="30px" />,
    title: "Manage Hubs",
    link: "/home/hotspot-hubs",
    id: "step-2",
  },
  {
    icon: <Token height="30px" className="self-start" width="30px" />,
    title: "Generate Data Token",
    link: "/home/token",
    id: "step-3",
  },
  {
    icon: <DataBought height="30px" className="self-start" width="30px" />,
    title: "Create Hub Form",
    link: "/home/hub-form",
    id: "step-4",
  },
  {
    icon: <ParentalControl height="30px" className="self-start" width="30px" />,
    title: "Set Parental Control",
    link: "/home/parental-control",
    id: "step-5",
  },
  {
    icon: <HubSetup height="30px" className="self-start" width="30px" />,
    title: "Set up a Hub",
    link: "/home/dashboard/hub-setup",
    id: "step-6",
  },
  {
    icon: <Caption height="30px" className="self-start" width="30px" />,
    title: "Create an ads caption",
    link: "/home/advert-control/caption",
    id: "step-7",
  },
  {
    icon: <Balance height="30px" className="self-start" width="30px" />,
    title: "Withdraw Ads Balance",
    link: "/home/advert-control/overview",
    id: "step-8",
  },
  {
    icon: <Business height="30px" className="self-start" width="30px" />,
    title: "Manage Clusters",
    link: "/home/cluster",
    id: "step-9",
  },
]