import { useRef, useState } from "react";
import TableMain from "../reusables/ReactDataTable";
import { parentalControlColumn} from "./data";
import { EmptyCard, TableLoader, Filter, } from "../reusables";
import { MobileFilterSearch } from "../hotspotHubs/OptionsStatus";
import AddIcon from "../../assets/icons/wallet/add.svg";
import { useParams } from "react-router-dom";
import { BlacklistAddress } from "./ParentalControlModals";
import { useGetBlacklistedAddressesQuery } from "../../features/api/parentalControlSlice";
import { SuccessModal } from "../reusables/SuccessModal";


const BlacklistedList = () => {
    const { deviceId, categoryId } = useParams();
    const [page, setPage] = useState<number>(1);
    const [option, toggleOption] = useState<string>("");
    const [searchValue, setSearch] = useState<string>("");
    const [AddressTypeFilter, setAddressType] = useState<string>("All");
    const [isSetAddress, toggleisSetAddress] = useState<boolean>(false);
    const [successStatus, setSuccessStatus] = useState<boolean>(false);

    const [platformName, setPlatformName] = useState<string>("");

    // Callback function to update platform name
    const handlePlatformUpdate = (platformUpdate: string) => {
        setPlatformName(platformUpdate);
    };

    const modalRef = useRef<HTMLElement>(null);

    const { data, isLoading, isFetching } = useGetBlacklistedAddressesQuery({
        deviceId: deviceId as string,
        Search: searchValue,
        CategoryId: categoryId || "",
        AddressTypeFilter: AddressTypeFilter === "All" ? "" : AddressTypeFilter,
        Page: page,
        PageSize: 30
    });

    return (
        <section className="w-[96%] mx-auto mt-3 mb-6 h-full">
            <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-8">
                <h3 className="text-sm font-semibold text-[#6b6b6b] dark:text-clrPlaceholder mb-2">
                    Blacklisted Addresses
                </h3>
                <p className="text-xs text-[#6B6B6B] dark:text-clrPlaceholder mb-8"> Add and delete website url’s you wish to restrict on this device</p>
                <div className="flex flex-wrap items-end gap-4 mb-8 md:flex-nowrap">
                    
                    <MobileFilterSearch>
                        <Filter
                        modalRef={modalRef}
                        options={["All", "System", "Custom"]}
                        toggleFilter={toggleOption}
                        selectedFilterOption={option}
                        currentFilterOption={"TYPE"}
                        selectFilterOption={setAddressType}
                        selectedOption={AddressTypeFilter}
                        label={"Address Type"}
                        />
                        <div>
                            <label
                                htmlFor=""
                                className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
                            >
                                Search
                            </label>
                            <input
                                type="text"
                                name=""
                                id=""
                                value={searchValue}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search Platform"
                                className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
                            />
                        </div>
                    </MobileFilterSearch>
                    <button
                        type="button"
                        onClick={()=> toggleisSetAddress(!isSetAddress)}
                        className="flex cursor-pointer justify-between items-center w-fit h-8 mt-3 px-4 gap-3 bg-primary rounded-[28px] transition duration-500 ease-in-out transform hover:bg-[#e3be27] hover:scale-[0.95]"
                    >
                        <img src={AddIcon} alt="Add URL" width={18} height={18} />
                        <p className="text-xs font-medium text-white">{"Add URL"}</p>
                    </button>
                </div>

                <div>
                    {isLoading || isFetching ? (
                        <TableLoader />
                    ) : (
                        <TableMain
                            defaultData={data?.data?.records || []}
                            columns={parentalControlColumn}
                            totalPages={data?.data.totalPages || 0}
                            pageSize={data?.data.pageSize || 0}
                            currentPage={data?.data.currentPage || 0}
                            setPage={setPage}
                        />
                    )}
                    {!isLoading && !isFetching && (!data || !data.data || data.data.records.length === 0) && (
                        <EmptyCard 
                            description={searchValue ? `${searchValue} not found` : "You have not blacklisted any addresses for this device" }
                        />
                    )}
                    

                </div>
                {isSetAddress 
                && <BlacklistAddress 
                    handleModal={()=> toggleisSetAddress(!isSetAddress)} 
                    deviceId={deviceId as string}
                    handleSuccessModalOpen={() => setSuccessStatus(true)}
                    handlePlatformUpdate={handlePlatformUpdate}
                />}

                {successStatus && (
                    <SuccessModal
                        title={"Address Blacklisted"}
                        btnText="Proceed"
                        description={`You just blacklisted the platform - ${platformName}`}
                        handleModal={() => setSuccessStatus(false)}
                    />
                )}
            </div>
        </section>
    );
};

export default BlacklistedList;
