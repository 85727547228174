import { useState } from "react";
import TableMain from "../reusables/ReactDataTable";
import { trafficColumn } from "./data";
import { SearchRow } from "./common";
import { DoughnutChart } from "./DoughnutChart";
import { EmptyCard, TableLoader } from "../reusables";
import { useParams } from "react-router-dom";
import { useGetVisitedDomainQuery } from "../../features/api/analyticsSlice";
import { exportTableData, generateRandomShadeOfColor } from "../../Helper";

interface InfoBoxProps {
  label: string;
  value: number;
  infoBg: string;
}
const TrafficSummary = () => {
  
  const [page, setPage] = useState<number>(1);
  const {analyticId } = useParams();
  const [filterYear, setFilterYear] = useState<string>('2024');
  const [filterTimeline, setFilterTimeline] = useState<string>('Yearly');
  const [filterMonth, setFilterMonth] = useState<string>('August');

  const { data, isLoading, isFetching } = useGetVisitedDomainQuery({
    deviceId: analyticId || '',
    timeLine: filterTimeline,
    month: filterMonth,
    year: filterYear
  });

  return (
    <div className="flex flex-col gap-4 mt-10 xl:flex-row">
    <TrafficAnalyticsChart />
    <div className="border border-[#C4C4C433] rounded-lg flex-1">
      <div className="p-5">
        <h4 className="mb-3 text-xs font-medium text-clrTextLightGray dark:text-white">
          Traffic Breakdown
        </h4>
        <SearchRow 
          setFilterYear={setFilterYear} 
          filterYear={filterYear} 
          filterTimeline={filterTimeline}
          setFilterTimeline={setFilterTimeline}
          setFilterMonth={setFilterMonth}
          filterMonth={filterMonth}
          exportTable={()=> exportTableData(data?.data || [], "Traffic Breakdown")}
        />
      </div>
      {isLoading || isFetching ? (
          <TableLoader />
        ) : (
          <TableMain
            defaultData={data?.data || []}
            columns={trafficColumn}
            totalPages={0}
            pageSize={30}
            currentPage={page}
            setPage={setPage}
          />
        )}

        {!isLoading && !isFetching && (data?.data.length || 0) === 0 && (
          <EmptyCard description="You don't have any traffic analytics!" />
        )}
    </div>
  </div>
  )
}

export default TrafficSummary

const TrafficAnalyticsChart = () => {
  const {analyticId } = useParams();

  const { data, isLoading, isFetching } = useGetVisitedDomainQuery({
    deviceId: analyticId || '',
    timeLine: "Yearly",
    year: "2024",
    month: "",
  });

  const chartData: any[] = []

  data?.data?.map((each) =>{
    chartData.push({
      label: each?.category?.name,
      value: each?.percentageOfTotalVisits,
      color: generateRandomShadeOfColor(),
      infoBg: 'bg-[#332901]',
    })
  })

  return (
    <div className="space-y-6 md:space-y-12 border border-[#C4C4C433] px-4 md:px-6 md:pt-9 py-6 md:pb-14 rounded-lg">
      <DoughnutChart data={chartData} />
      <div className="grid grid-cols-2 gap-3 md:gap-5 md:grid-cols-3 ">
        {
          chartData?.map((each: any) => {
            return <DynamicInfoBox key={each?.label} label={each.label} value={each.value} infoBg={each.infoBg} />;
          })
        }
         {!isLoading && !isFetching && (data?.data.length || 0) === 0 && (
          <EmptyCard description="You don't have any traffic analytics!" />
        )}
      </div>
    </div>
  );
};

const DynamicInfoBox = ({ label, value, infoBg }: InfoBoxProps) => (
  <div className="flex gap-2 md:w-[120px]">
    <div
      className={`w-1 rounded ${infoBg} h-[20px]`}
    />
    <div className={`flex justify-between items-center w-full`}>
      <p className={`text-[#4E5969] text-x10`}>{label}</p>
      <p className="text-[#1D2129] text-[15px] leading-[21px] font-medium">{value}
        <span className="font-normal text-[#86909C] text-x10">%</span>
      </p>
    </div>
  </div>
);