import { useRef, useState, DragEvent, useEffect } from 'react';
import { ReactComponent as AddIcon } from "../../assets/icons/wallet/add.svg";
import { ReactComponent as MoreIcon } from "../../assets/hub/more.svg";
import { ReactComponent as ArrowRight } from "../../assets/hub/arrow-right.svg";
import { ReactComponent as Cross } from "../../assets/hub/cross.svg";
import { ReactComponent as EditIcon } from "../../assets/hub/edit.svg";
import { ReactComponent as PreviewIcon } from "../../assets/hub/preview.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as DownloadIcon } from "../../assets/hub/download.svg";
import { Button, ErrorMsg, SeeTourButton } from "../reusables";
import { IntroducingHubForm } from "./Onboarding";
import Joyride, { Step } from "react-joyride";
import { ContentOption, EditContentModal } from "./hubModals";
import { useParams } from "react-router-dom";
import FormSetting from './FormSetting';
import { contentType, renderResponse } from './FormTypes';
import { ContentOptions } from './data';
import ImageSliders from './ImageSliders';
import { PreviewForm } from './PreviewForm';
import { sliceText } from '../../Helper';
import { useGetTemplateFormsQuery } from '../../features/api/dynamicFormSlice';
import useOutsideClick from '../../Hooks/useOutsideClick';

export type QuestionType = "Short Text" | "Long Text" | "Multiple Choice" | "Single Choice" | "Dropdown" | "Ratings" | "Upload Document" | "Prized Items";


type ImageList = {
  src: string,
  alt: string,
  classes: string[]
}

type Options = {
  value: string,
  text?: string,
  id?: string,
  label?: string,
  classes?: string[]
}
export interface IHubFormQuestion {
  type: string;
  id?: string;
  name: string;
  label: string;
  required?: boolean;
  classes?: string[];
  maxLength?: {
    length: number,
    isEnabled: boolean
  },
  href?: any;
  download?: boolean;
  images?: ImageList[];
  options?: Options[];
  rows?: number
}

const initialContentBody: IHubFormQuestion = {
  type: "text",
  id: "",
  name: "",
  label: "",
  classes: ["input-field"],
  required: true,
  maxLength: {
    length: 0,
    isEnabled: false
  }
}

const integral: IHubFormQuestion = {
  type: "tel",
  id: "phone",
  name: "phone",
  label: "Phone Number",
  required: true,
  classes: ["input-field"],
  maxLength: {
    length: 0,
    isEnabled: false
  }
}

const FormContent = () => {
  const [isJoyrideActive, setIsJoyrideActive] = useState(false);
  const [isCloseModal, setIsCloseModal] = useState(true);
  const [isEditContentOpen, setIsEditContentOpen] = useState(false);
  const [isPreviewForm, setIsPreviewForm] = useState(false);
  const modalRefEditContent = useRef<HTMLElement>(null);
  const modalRef = useRef<HTMLElement>(null);
  const [sliderImages, addImageSlider] = useState<File[]>([])
  const [imageUrl, addImageUrl] = useState<string[]>([]);
  const [isContentOptions, showContentOptions] = useState<boolean>(false);
  const [errMsg, setErr] = useState<string>("");
  const { formName, formId } = useParams();


  const {data: templatedForms} = useGetTemplateFormsQuery({search: "" })

  // Add new question
  const [formQuestions, setFormQuestions] = useState<IHubFormQuestion[]>([{ ...integral }]);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);

  const [dropdownOption, setOption] = useState<string>("");
  const [step, setStep] = useState<number>(0)

  // Function to handle creating a form question
  const handleUpdateFormQuestion = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const updatedFormQuestions = [...formQuestions];
    if (updatedFormQuestions.length > 0 && updatedFormQuestions[selectedQuestionIndex]) {
      (updatedFormQuestions[selectedQuestionIndex] as any)[name] = value;
      (updatedFormQuestions[selectedQuestionIndex] as any)["name"] = value;
      setFormQuestions(updatedFormQuestions);
    }
  }

  const handleCloseModal = () => {
    setIsEditContentOpen(false);
  };

  useOutsideClick(modalRefEditContent, handleCloseModal);
  useOutsideClick(modalRef, ()=> showContentOptions(false));

  function generateUniqueId() {
    const timestamp = new Date().getTime();
    const randomNum = Math.floor(Math.random() * 10000);
    return `${timestamp}${randomNum}`;
  }


  const handleOptionChange = (option: QuestionType, isRequired: boolean, maxLength: number) => {
    setErr("")
    const updatedFormQuestions = [...formQuestions];
    switch (option) {
      case "Short Text":
        updatedFormQuestions[selectedQuestionIndex] = {
          type: "text",
          id: selectedQuestionIndex.toString(),
          name: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          label: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          required: isRequired,
          classes: ["input-field"],
          maxLength: {
            length: maxLength,
            isEnabled: maxLength ? true : false
          }
        }
        setFormQuestions(updatedFormQuestions)
        break;
      case "Long Text":
        updatedFormQuestions[selectedQuestionIndex] = {
          type: "textarea",
          id: selectedQuestionIndex.toString(),
          name: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          label: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          required: isRequired,
          classes: ["input-field"],
          rows: 4,
          maxLength: {
            length: maxLength,
            isEnabled: maxLength ? true : false
          }
        }
        setFormQuestions(updatedFormQuestions)
        break;
      case "Ratings":
        updatedFormQuestions[selectedQuestionIndex] = {
          type: "rating",
          id: selectedQuestionIndex.toString(),
          name: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          label: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          required: isRequired,
          options: [
            {
              id: "rating1",
              value: "1",
              label: "Rating1",
              classes: ["rating", "rating-label"]
            },
            {
              id: "rating2",
              value: "2",
              label: "Rating2",
              classes: ["rating", "rating-label"]
            },
            {
              id: "rating3",
              value: "3",
              label: "Rating3",
              classes: ["rating", "rating-label"]
            },
            {
              id: "rating4",
              value: "4",
              label: "Rating4",
              classes: ["rating", "rating-label"]
            },
            {
              id: "rating5",
              value: "5",
              label: "Rating5",
              classes: ["rating", "rating-label"]
            }
          ],
        }
        setFormQuestions(updatedFormQuestions)
        break;
      case "Dropdown":
        updatedFormQuestions[selectedQuestionIndex] = {
          type: "dropdown",
          id: selectedQuestionIndex.toString(),
          name: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          label: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          required: isRequired,
          classes: ["dropdown"],
          options: [],
        }
        setFormQuestions(updatedFormQuestions)
        break;
      case "Upload Document":
        updatedFormQuestions[selectedQuestionIndex] = {
          type: "file",
          name: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          label: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          classes: ["file-link"],
          href: {}
        }
        setFormQuestions(updatedFormQuestions)
        break;
      case "Single Choice":
        updatedFormQuestions[selectedQuestionIndex] = {
          type: "radio",
          id: selectedQuestionIndex.toString(),
          name: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          label: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          options: [],
          required: isRequired
        }
        setFormQuestions(updatedFormQuestions)
        break;
      case "Multiple Choice":
        updatedFormQuestions[selectedQuestionIndex] = {
          type: "checkbox",
          id: selectedQuestionIndex.toString(),
          name: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          label: updatedFormQuestions[selectedQuestionIndex]?.label || "",
          options: [],
          required: isRequired
        }
        setFormQuestions(updatedFormQuestions)
        break;
      default:
        break;
    }
  }

  const handleOptionUpdate = (option: QuestionType) => {
    const updatedFormQuestions = [...formQuestions];
    switch (option) {
      case "Dropdown":
        (updatedFormQuestions[selectedQuestionIndex] as any)?.options.push(
          {
            value: dropdownOption,
            text: dropdownOption,
            id: generateUniqueId(),
          }
        )
        break;
      case "Single Choice":
        (updatedFormQuestions[selectedQuestionIndex] as any)?.options.push(
          {
            value: dropdownOption,
            id: generateUniqueId(),
            classes: ["radio", "radio-label"],
            label: dropdownOption,
          }
        )
        break;
      case "Multiple Choice":
        (updatedFormQuestions[selectedQuestionIndex] as any)?.options.push(
          {
            value: dropdownOption,
            id: selectedQuestionIndex.toString(),
            classes: ["checkbox", "checkbox-label"],
            label: dropdownOption,
          }
        )
        break;
      default:
        break;
    }

    setOption("")
  }

  const handleQuestionDelete = (index: number) => {
    setFormQuestions(prevFormQuestions => {
      const updatedQuestions = [...prevFormQuestions]; 
      updatedQuestions?.splice(index, 1)
      return updatedQuestions;
    });
  }


  //  // Function to start the tooltip tour
   const startJoyride = () => {
    setIsJoyrideActive(true);
  };

  const handleSeeTour = () => {
    setIsJoyrideActive(true);
  };

  // // Shows the steps tours 
  const [{ steps }] = useState<{
    // run: boolean;
    steps: Step[]; // Define the type for the steps array
  }>({
    // run: true,
    steps: [
      {
        content: (
          <p className="text-left text-white text-x10">
            This allows you to add your preferred form type
          </p>
        ),
        disableBeacon: true,
        placement: "bottom",
        target: ".step-1",
        title: (
          <span className="text-sm text-left px-[10px] text-white">Add form content</span>
        ),
      },
      {
        content: (
          <p className="text-left text-white text-x10">
            This includes pre-existing input forms.
          </p>
        ),
        placement: "bottom",
        target: "#step-2",
        title: <span className="text-sm text-left px-[10px] text-white">Form content </span>,
      },
      {
        content: (
          <p className="text-left text-white text-x10">
            This section houses the created form element, offering a preview on the splash screen with editing capabilities.
          </p>
        ),
        placement: "top",
        // placementBeacon: "left",
        target: "#step-3",
        title: <span className="text-sm text-left px-[10px] text-white">Content body</span>,
        
      },
      {
        content: (
          <p className="text-left text-white text-x10">
            Edit active forms and add images to content body with this feature.
          </p>
        ),
        placement: "right",
        target: "#step-4",
        title: <span className="text-sm text-left px-[10px] text-white">Edit content</span>,
      },
    ],
  });

  const customStyles = {
    options: {
      arrowColor: "#000",
      primaryColor: "black",
      color: "white",
      width: 250,
      zIndex: 1000,
      borderRadius: "20px",
      backgroundColor: "#000",
    },
    buttonNext: {
      backgroundColor: "white",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#25346A",
      color: "#25346A",
      borderRadius: "40px",
      fontSize: "0.625rem",
      padding: "4px 8px",
    },
    buttonBack: {
      color: "white",
      borderRadius: "40px",
      fontSize: "0.625rem",
    },
  };

  const handleDeleteFile = () => {
    const updatedFormQuestions = [...formQuestions];
    (updatedFormQuestions[selectedQuestionIndex] as any).href = {}
    setFormQuestions(updatedFormQuestions)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    const updatedFormQuestions = [...formQuestions];
    if (selectedFile) {
      (updatedFormQuestions[selectedQuestionIndex] as any).href = selectedFile
    }
    setFormQuestions(updatedFormQuestions)
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const updatedFormQuestions = [...formQuestions];
    if (file) {
      (updatedFormQuestions[selectedQuestionIndex] as any).href = file
    }
    setFormQuestions(updatedFormQuestions)
  };
  const handleProceed = () => {
    const lastQuestion = formQuestions[formQuestions.length - 1];
  
    if (!lastQuestion?.label) {
      setErr("Please add a label for this question");
    } else if (
      (lastQuestion?.type === "radio" ||
        lastQuestion?.type === "dropdown" ||
        lastQuestion?.type === "checkbox") &&
      (!lastQuestion?.options || lastQuestion?.options.length === 0)
    ) {
      setErr("Please add options to this question");
    }else{
      setStep(3)
    }
  };

  const handleRemove = (id: string) => {
    const updatedFormQuestions = [...formQuestions];

    const updatedOptions = updatedFormQuestions[selectedQuestionIndex]?.options?.filter((option) => {
      return option.id !== id;
    });
    (updatedFormQuestions[selectedQuestionIndex] as any).options  = updatedOptions
    setFormQuestions(updatedFormQuestions);
  };
  

  const handleJoyrideCallback = (data: { action: any; index: any; }) => {
    const { action, index } = data;

    if (index === 2 && action === 'update') {
      setIsEditContentOpen(true); // Open the modal when reaching the last step and clicking "Next"
    }

    if (action === 'reset' && isEditContentOpen) {
      setIsEditContentOpen(false); // Close the modal when Joyride finishes
    }
  };
  
  useEffect(() => {
    if (formId && templatedForms) {
      let form = templatedForms.data.find((f) => f.templateId === formId);
      if (form) {
        fetch(form.payloadUrl)
          .then((res) => {
            if(res)
            if (!res.ok) {
              throw new Error("Network response was not ok");
            }
            return res.json();
          })
          .then((data) => {
            const flattenedQuestions = data.form.fields.flat();
            setFormQuestions(flattenedQuestions);
          })
          .catch((error) => {
            console.error("Fetch error:", error);
          });
      }
    }
  }, [formId, templatedForms]);
  

  return (
    <>
      <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={isJoyrideActive}
        steps={steps}
        hideCloseButton
        scrollToFirstStep
        styles={customStyles}
        // tooltipComponent={TourTooltip}
        // stepComponent={CustomStep}
      />
      {!isJoyrideActive && isCloseModal && <IntroducingHubForm handleGetStarted={startJoyride} handleCloseModal={()=> setIsCloseModal(false)} />}
      {step === 0 ?
        <section className="py-3 bg-clrBg w-[96%] mx-auto pb-6 mt-3 dark:bg-clrDarkBg2 overflow-y-auto">
          <div className="flex flex-col md:grid grid-cols-[max-content,1fr,max-content] md:px-6 bg-white rounded-lg md:min-h-[9rem]">
            <div id="step-1" className="flex md:flex-col items-center justify-between md:justify-center py-4 px-4 md:p-0 md:mr-7 step-1">
              <h2 className='md:mb-1 text-sm font-semibold text-black'>Form Content</h2>
              <button
                className={`flex cursor-pointer justify-between items-center w-fit h-8 md:mt-3 px-4 gap-3 bg-primary rounded-[28px] transition duration-500 ease-in-out transform hover:bg-[#e3be27] hover:scale-[0.95] disabled:opacity-50`}
                onClick={() => {
                  const newState = [...formQuestions];
                  newState.push({ ...initialContentBody });
                  const lastIndex = newState.length - 1;
                  setFormQuestions(newState);
                  setSelectedQuestionIndex(lastIndex);
                }
                }

                disabled={
                  formQuestions?.some((question) => question.label === "" 
                  || 
                    ((formQuestions[selectedQuestionIndex]?.type === "radio" || 
                    formQuestions[selectedQuestionIndex]?.type === "dropdown" || 
                    formQuestions[selectedQuestionIndex]?.type === "checkbox") &&  
                    formQuestions[selectedQuestionIndex]?.options?.length === 0)
                  )
                  
                }
              >
                <AddIcon />
                <p className="text-xs font-medium text-white">Add</p>
              </button>
            </div>
            <div id="step-2" className={`bg-primary bg-opacity-[2%] border-x border-primary/5 border-solid px-5 py-6 flex flex-wrap gap-5`}>
              {/* new question summary */}
              {formQuestions?.map((question, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedQuestionIndex(index)}
                  className={`flex items-center gap-2 px-3 py-2 border border-solid rounded-lg bg-clrBg2 w-fit h-fit
                  ${index === selectedQuestionIndex ? "border-clrDarkYellow/40 " : "border-transparent cursor-pointer"}`}>
                  <p id={`question-${index + 1}`} className="text-xs font-normal text-black">{index + 1}</p>
                  <div className="flex items-center justify-center w-5 h-5 rounded-full bg-primary bg-opacity-10">
                    <img src={ContentOptions[contentType(question?.type)]?.image} />
                  </div>
                  <p
                    className={`text-xs font-normal text-black italic`}
                  >
                    {question.label ? question.label : "No question"}
                  </p>
                  {question.label && index !== 0 && 
                  <button type='button'className='relative cursor-pointer'>
                     <MoreIcon  onClick={()=> showContentOptions(!isContentOptions)}/>  
                     {isContentOptions && index === selectedQuestionIndex && <ContentOption modalRef={modalRef} handleDelete={()=> handleQuestionDelete(selectedQuestionIndex)} />}
                    </button>}
                 
                </button>
              ))}

              {/* <button className="items-start p-2 bg-opacity-50 rounded-lg bg-primary">
              <p className="text-xs font-normal text-primary">Preview</p>
            </button> */}
            </div>
            <div className="flex md:flex-col items-center justify-between md:justify-center gap-3 md:ml-7 p-4 md:p-0">
              <div className="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 border-2 border-solid rounded-full border-primary bg-primary bg-opacity-10">
                <p className="text-xs md:text-sm font-normal text-primary" >{selectedQuestionIndex + 1}/{formQuestions.length}</p>
              </div>
              <SeeTourButton handleClick={handleSeeTour} />
            </div>
          </div>
          <div id="step-3" className="relative flex flex-col bg-white rounded-lg px-4 py-[2.125rem] md:pl-11 md:pr-5 mt-4 min-h-[calc(100vh-345px)] after:content[' '] after:absolute after:w-1/2 after:h-[0.4375rem] after:bg-primary after:rounded-ss-lg after:top-0 after:left-0">
            <div className="flex justify-between">
              <p className="mb-8 md:mb-12 text-sm md:text-base font-semibold text-clrGray">Content body</p>
              <button className="flex gap-2" onClick={()=> setIsPreviewForm(!isPreviewForm)}>
                <p className="mb-8 md:mb-12 text-sm md:text-base font-semibold text-primary">Preview</p>
                <PreviewIcon />
              </button>
            </div>

            <div className="flex gap-6 md:gap-12 md:ml-6">
              <div className="flex items-center gap-2 h-fit">
                <p className="text-base md:text-lg font-semibold text-black">{selectedQuestionIndex + 1}</p>
                <ArrowRight className="w-4 h-4" />
              </div>
              <div className="grid gap-6">
                <div>
                  <div className="flex flex-wrap items-center gap-5 mb-3">
                    <input
                      type="text"
                      name="label"
                      value={formQuestions ? formQuestions[selectedQuestionIndex]?.label : ""}
                      onChange={handleUpdateFormQuestion}
                      placeholder="Type in your question here"
                      className="block focus:outline-none text-sm md:text-base placeholder:!text-sm md:placeholder:!text-base md:placeholder:!font-medium italic font-medium text-black md:min-w-[14.4rem]" />
                    <button onClick={() => setIsEditContentOpen(true)} className="flex gap-2">
                      <EditIcon className="w-4 h-4" />
                      <p className="text-xs font-normal text-primary">Edit properties</p>
                    </button>
                  </div>
                  {/* <input 
                  type="text" 
                  name="description" 
                  value={formQuestions.length > 0 ? formQuestions[selectedQuestionIndex]?.description : ""} 
                  onChange={handleUpdateFormQuestion}
                  className="text-sm italic font-normal outline-none text-clrGray focus:outline-none" 
                  placeholder="Description (optional)" 
                /> */}

                  {formQuestions[selectedQuestionIndex] && formQuestions[selectedQuestionIndex]?.type !== "file" &&
                    renderResponse(
                      formQuestions[selectedQuestionIndex]?.type as string,
                      formQuestions[selectedQuestionIndex]?.options,
                      formQuestions[selectedQuestionIndex]?.href,
                      '',
                      handleRemove
                    )
                  }
                </div>

                {/* upload document */}
                {formQuestions[selectedQuestionIndex]  && formQuestions[selectedQuestionIndex]?.type === "file" ?
                  (
                    <div
                      className="bg-[#C4C4C457] border border-solid w-96 h-36 border-[#C4C4C4] flex flex-col items-center justify-center rounded-lg mt-2"
                      onDragOver={(e) => handleDragOver(e)}
                      onDragEnter={(e) => handleDragEnter(e)}
                      onDragLeave={(e) => handleDragLeave(e)}
                      onDrop={(e) => handleDrop(e)}
                    >
                      <div className="flex flex-col items-center justify-center gap-4">
                        <p className="text-xs font-normal text-clrGray">
                          <span>
                            <label htmlFor="file" className="underline cursor-pointer">
                              Upload
                            </label>
                            <input
                              type="file"
                              name="file"
                              id="file"
                              onChange={handleChange}
                              accept=".docx 
                              .xlx, 
                              .xlsx, 
                              .csv, 
                              .pdf, 
                              .png, 
                              .jpg, 
                              .jpeg"
                              className="absolute overflow-hidden opacity-0 z-index--1"
                            />
                          </span> or drop a file right here
                        </p>
                        {/* <p className="text-xs font-normal text-clrGray">PDF. </p> */}
                      </div>
                      {formQuestions[selectedQuestionIndex]?.href.name &&

                        <div className="flex flex-col w-4/5 gap-4 ">
                          <button type="button" className="self-end" onClick={handleDeleteFile}>
                            <DeleteIcon className="w-5 h-5" />
                          </button>
                          <div className="flex items-center self-center gap-1 px-5 py-3 bg-black rounded-full bg-opacity-20 w-fit">
                            <DownloadIcon className="w-4 h-4" />
                            <p className="text-sm font-semibold text-black">{sliceText(formQuestions[selectedQuestionIndex]?.href.name, 20)}</p>
                          </div>
                        </div>
                      }
                    </div>

                  ) : null}

                {formQuestions[selectedQuestionIndex] && ["checkbox", "radio", "dropdown"].includes(formQuestions[selectedQuestionIndex]!.type) ? (<div className="flex gap-5 mt-3">
                  <input
                    type="text"
                    placeholder={`Option ${((formQuestions[selectedQuestionIndex] as any)?.options.length || 0) + 1}`}
                    onChange={(e) => setOption(e.target.value)}
                    value={dropdownOption}
                    className="relative flex items-center justify-between w-full pb-2 text-xs border-b border-solid placeholder:!text-clrGray/30 placeholder:text-xs border-[#E5B91033] focus:outline-none focus:border-primary" />
                  <button
                    type="button"
                    className="flex items-center gap-1 w-full"
                    onClick={() => handleOptionUpdate(formQuestions[selectedQuestionIndex]!.type === "checkbox" ? "Multiple Choice" : formQuestions[selectedQuestionIndex]!.type === "dropdown" ? "Dropdown" : "Single Choice")}
                  >
                    <p className="text-xs font-normal text-clrGray">Add choice</p>
                    <div className="flex items-center justify-center w-5 h-5">
                      <Cross />
                    </div>
                  </button>
                </div>
                ) : null}
              </div>
            </div>
            <div className='mt-2'>
              <ErrorMsg errMsg={errMsg} />
            </div>
            
            <div className="flex flex-col justify-end gap-3 mt-auto ml-auto md:w-fit">
              <Button
                type="button"
                text={"Proceed to form settings"}
                handleClick={handleProceed}
                fontWeight="font-normal"
                disabled={formQuestions[0]?.label === "" ? true : false}
              />
            </div>
          </div>

          {/* remove later */}
          <button className="hidden" onClick={() => setSelectedQuestionIndex(3)}></button>

          {isEditContentOpen &&
            <EditContentModal
              modalRef={modalRefEditContent}
              handleCloseModal={handleCloseModal}
              id={"step-4"}
              handleOptionChange={handleOptionChange}
              form={formQuestions[selectedQuestionIndex] as IHubFormQuestion}
            />
          }
        </section> :
        step === 1 ?
          <ImageSliders 
            handleNavigate={setStep} 
            imageSliders={sliderImages} 
            imageUrls={imageUrl}
            handleSubmit={addImageSlider} 
            handleImageUrls={addImageUrl}
          /> 
          :
          <FormSetting 
            formName={formName as string}  
            sliderImages={sliderImages} 
            fieldList={formQuestions} 
            handleNavigate={setStep}
            imageUrl={imageUrl}
          />
      }

      {isPreviewForm && <PreviewForm formQuestions={formQuestions} handleCloseModal={()=> setIsPreviewForm(false)} imageUrl={imageUrl} />}

    </>
  )
}

export default FormContent