import { useEffect, useRef, useState } from "react";
import { ReactComponent as NextArrow } from "../../assets/subscription/next-step-arrow.svg";
import { Button } from "../reusables";
import { useNavigate, useParams } from "react-router-dom";
import EnterPin from "../reusables/EnterPin";
import VerifyPin from "../reusables/VerifyPin";
import { SubscriptionRes } from "../../features/api/subscriptionSliceTypes";
import { useLazyGetPackageQuery, useSusbcribePackageMutation } from "../../features/api/subscriptionSlice";
import { numberWithCommasWithoutDecimal } from "../../Helper";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { useGetSupportedCurrenciesQuery, useLazyGetTotPQuery } from "../../features/api/walletSlice";


export type PackageDetail = {
  id: string,
  name: string,
  monthlyFee: number,
  annualFee: number,
  doesDiscountApply: boolean,
  discountedMonthlyFee: number,
  discountedAnnualFee: number,
  featureList: [
    {
      name: string,
      type: string,
      configurations: string[]
    }
  ]
}

const SelectedPlan = () => {
  const { planId } = useParams();
  const [step, setStep] = useState<number>(1);
  const [pin, setPin] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [errMsg, setError] = useState<string>("");
  const [successRes, setSuccessRes] = useState<SubscriptionRes>();

  const [subscribeUser, { isLoading }] = useSusbcribePackageMutation();
  const [trigger] = useLazyGetTotPQuery()
  const { data: currencies } =
    useGetSupportedCurrenciesQuery("Subscription");


  const [isEnterPin, setIsEnterPin] = useState(false);
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);

  const modalRefEnterPin = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsEnterPin(false);
    setIsVerifyEmail(false);
  };

  useOutsideClick(modalRefEnterPin, handleCloseModal);

  const handleNext = () => {
    setIsEnterPin(true);
  }

  //Set the User's pin
  const handlePin = (res: string) => {
    setPin(res);
  };

  //Send OTP to the user
  const handleSendOTP = () => {
    const currencyId = currencies?.data?.currencies[0]?.id ?? 0;

    if (pin.length === 4) {
      setIsVerifyEmail(true);
      setIsEnterPin(false);
      trigger({category: "Subscription", currencyId, channel: "Internal" })
    }
  };

  //Save the user's OTP
  const handleOtp = (res: string) => {
    setOtp(res);
  };

  //Function to handle subscription step flow
  const renderStep = () => {
    switch (step) {
      case 1:
        return <PreviewPlan handleNext={handleNext} currencyCode={currencies?.data?.currencies[0]?.code || ""}/>;

      case 2:
        return <SuccessPlan sub={successRes as SubscriptionRes}  />;
      default:
        return;
    }
  };

  const handleSubscription = () => {
    setError("");

    if (!otp) {
      setError("Please enter a valid OTP")
    } else {
      let data = {
        packageId: planId as string,
        currencyId: 1,
        detail: {
          planType: "Monthly",
          pin,
          otp
        }
      }
      subscribeUser(data)
        .unwrap()
        .then((res) => {
          if (res.success) {
            console.log(res, 'i subscribed')
            setSuccessRes(res?.data);
            setStep(2)
            setIsVerifyEmail(false);
          } else {
            setError(res?.message)
          }

        })
        .catch((err) => { setError(err.data.message) })
    }

  }

  return (
    <section className="py-3 bg-clrBg w-[96%] mx-auto pb-6 mt-3 dark:bg-clrDarkBg2 overflow-y-auto">
      <div className="flex items-center justify-center">
        <div className="flex items-center justify-between w-4/5 md:w-[220px] mb-5 mt-1 md:mb-10 md:mt-5">
          <p className="text-base font-semibold text-[#1A1A1A]">Preview</p>
          <NextArrow />
          <p className={`${step > 1 ? "text-base font-semibold text-[#1A1A1A]" : "text-sm font-normal text-clrGray "}`}>Success</p>
        </div>
      </div>

      {renderStep()}

      {isEnterPin &&
        <EnterPin
          handleModal={handleCloseModal}
          handleContinue={handleSendOTP}
          handleChange={handlePin}
          errMsg={""}
          resendOtp={() => console.log("resend OTP")}
          loading={false}
        />
      }

      {isVerifyEmail &&
        <VerifyPin
          handleModal={handleCloseModal}
          resendOtp={() => { }}
          handleContinue={handleSubscription}
          handleChange={handleOtp}
          errMsg={errMsg}
          loading={isLoading}
        />
      }
    </section>
  )
}

export default SelectedPlan

const PreviewPlan = ({ handleNext, currencyCode }: { handleNext: () => void, currencyCode: string }) => {

  const { planId, billingType } = useParams();

  const [packageDetail, setPackageDetail] = useState<PackageDetail>();
  const [billingCycle, setBillingCycle] = useState<number>(Number(billingType) || 1);

  const [trigger] = useLazyGetPackageQuery();

  useEffect(() => {
    if (planId) {
      trigger(planId)
        .unwrap()
        .then((res) => {
          setPackageDetail(res.data);
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [planId])


  return (
    <div className="flex flex-col bg-white rounded-xl w-[96%] md:w-[705px] mx-auto p-5 md:px-[2.625rem] md:py-10">
      <div className="flex flex-col md:flex-row gap-3 md:items-center justify-between">
        <div>
          <h4 className="mb-2 text-xl font-semibold text-black">Preview</h4>
          <p className="text-xs font-medium text-clrGray">Please review and authorize the payment for this plan</p>
        </div>
      </div>
      <div className="mb-10 mt-9">
        <div>
          <div className="mb-6">
            <p className="mb-2 text-xs font-medium text-clrGray">Selected plan</p>
            <p className="text-sm font-semibold text-black">{packageDetail?.name}</p>
          </div>
          <div>
            <p className="mb-6 text-xs font-medium text-clrGray">Billing cycle</p>
            <div className="flex gap-6">
              <div
                className="relative flex items-center gap-2 radio-input"
                onClick={() => {
                  setBillingCycle(1);
                }}
              >
                <input
                  type="radio"
                  name="billingCycle"
                  id=""
                  className=""
                  checked={billingCycle === 1}
                />
                {billingCycle === 1 && (
                  <div className="circle !left-[0.16em] !top-[0.2em] !absolute"></div>
                )}
                <label
                  htmlFor=""
                  className="block font-medium text-clrGray dark:text-clrPlaceholder text-x10 md:text-xs"
                >
                  Monthly
                </label>
              </div>
              <div
                className="relative flex items-center gap-2 radio-input"
                onClick={() => {
                  setBillingCycle(2);
                }}
              >
                <input
                  type="radio"
                  name="billingCycle"
                  id=""
                  className=""
                  checked={billingCycle === 2}
                />
                {billingCycle === 2 && (
                  <div className="circle !left-[0.19em] !top-[0.2em] !absolute"></div>
                )}
                <label
                  htmlFor=""
                  className="block font-medium text-clrGray dark:text-clrPlaceholder text-x10 md:text-xs"
                >
                  Yearly
                </label>
              </div>
            </div>
          </div>
          {packageDetail?.discountedAnnualFee ? <div className="flex justify-end w-[12.8rem] mt-[6px]">
            <span className="text-[0.625rem] font-medium text-clrGreen2 bg-clrGreen2 bg-opacity-20 py-[2.5px] px-[6px] rounded-lg">
              Save up to {packageDetail?.discountedAnnualFee}%
            </span>
          </div> : null}
        </div>

        <div className="mt-6">
          <div className="mb-6">
            <p className="mb-2 text-xs font-medium text-clrGray">Total Billing Amount</p>
            <p className="text-sm font-semibold text-black">
              {billingCycle === 1 ? numberWithCommasWithoutDecimal(packageDetail?.monthlyFee || 0) : numberWithCommasWithoutDecimal(packageDetail?.annualFee || 0)} {currencyCode}
            </p>
          </div>
        </div>
        <div>

        </div>
      </div>
      <div className="w-fit md:w-[16.875rem]">
        <Button
          text="Continue"
          handleClick={handleNext}
          type="button"
          wBtn="w-fit"
          fontWeight="font-semibold"
        />
      </div>
    </div>
  )
}

const SuccessPlan = ({sub} :{sub:SubscriptionRes }) => {
  const navigate = useNavigate();
  return (
    <section className="flex flex-col bg-white rounded-xl w-[96%] md:w-[705px] mx-auto p-5 md:px-[2.625rem] md:py-10">
      <div className="bg-primary bg-opacity-[0.02] rounded-2xl border border-primary/10 border-solid p-5 md:py-7 md:px-7">
        <div className="grid gap-2">
          <h4 className="text-xl font-semibold text-clrGreen2">Success</h4>
          <p className="text-3xl font-semibold text-[#000]">{sub?.amount} NGN</p>
          <p className="text-sm font-normal text-[#525252]">Congratulations on your {sub?.package} subscription purchase! Enjoy!</p>
        </div>
        <div className="mt-9">
          <h5 className="text-base font-semibold text-[#525252] mb-4">Transaction Details</h5>
          <div className="grid gap-4">
            <p className="flex flex-wrap items-center justify-between">
              <span className="text-sm font-normal text-[#525252]">Date</span>
              <p className="flex flex-col text-xs font-semibold text-black">
                <span className="self-end"> {sub?.subscriptionDate} </span>
                <span>12-10-2021</span>
              </p>
            </p>
            <p className="flex flex-wrap items-center justify-between">
              <span className="text-sm font-normal text-[#525252]">Amount</span>
              <span className="text-xs font-semibold text-black">{sub?.amount} NGN</span>
            </p>
            <p className="flex flex-wrap items-center justify-between">
              <span className="text-sm font-normal text-[#525252]">Subscription plan</span>
              <span className="text-xs font-semibold text-black">{sub?.package}</span>
            </p>
            <p className="flex flex-wrap items-center justify-between">
              <span className="text-sm font-normal text-[#525252]">Duration</span>
              <span className="text-xs font-semibold text-black">{sub?.duration} </span>
            </p>
            <p className="flex flex-wrap items-center justify-between">
              <span className="text-sm font-normal text-[#525252]">Date of Expiry</span>
              <p className="flex flex-col text-xs font-semibold text-black">
                <span className="self-end"> {sub?.expiryDate} </span>
                <span>12-10-2021</span>
              </p>
            </p>
            <p className="flex flex-wrap items-center justify-between">
              <span className="text-sm font-normal text-[#525252]">Transaction Reference</span>
              <span className="text-xs font-semibold text-black"> {sub?.reference}</span>
            </p>
          </div>
        </div>
        <div className="mx-auto mt-4 w-4/5 md:w-[23rem]">
          <Button
            text="Back"
            type="button"
            wBtn="w-fit"
            fontWeight="font-medium"
            handleClick={() => navigate("/home/subscription")}
          />
        </div>
      </div>
    </section>
  )
}